/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
import React from "react";
import "./btnComentario.css";
import { Link } from "react-router-dom";

export const BtnComentarYappa = (props) => {
    const { obj, message } = props;
    return(
        <button className="btnVerYappa">
            <Link className="linkBtnVer" to={`${obj}`}>
                <h3>{message}</h3>
                <img
                    className="imgComentarYappa"
                    src="https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/189794e5-5209-40a6-9fbb-3b4d97feee00/public"
                    alt="icon"
                />
            </Link>
        </button >
    )
}